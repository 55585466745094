<template>
    <div class="container py-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Admin Dashboard</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Datasets</li>
            </ol>
        </nav>
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>Datasets</h1>
            </div>
            <div class="col-4 d-md-flex justify-content-md-end">
                <div>
                    <router-link to="admindataset" class="btn btn-success mx-1">Add</router-link>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-if="!loaded" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class="list-group list-group-numbered">
                <div v-for="item in datasets" :key="item.id" class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{item.name}}</h5>

                        <div>
                            <router-link :to="{ path: 'admindatasetedit', query: { id: item.id } }" class="btn btn-warning mx-1">Edit</router-link>
                            <button @click="deleteDataset(item.id)" class="btn btn-danger mx-1">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    let datasets = [];
    let loaded = false;
    export default {
        name: 'AdminDatasets',
        data() {
            return {
                datasets: datasets,
                loaded: loaded
            }
        },
        methods: {
            deleteDataset(datasetId) {
                console.log("Not implemented: " + datasetId);
            },
            async getData() {
                await axios.get(process.env.VUE_APP_URL + "api/datasets")
                    .then(response => {
                        //console.log(response.data)
                        this.loaded = true;
                        this.datasets = response.data;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>
